<template>
<!--  <MainPage></MainPage>-->
  <router-view></router-view>
</template>

<script>
// import MainPage from "./components/MainPage.vue";
import router from '@/router/index';
export default {
  name: 'App',
  components: {
    // MainPage
  },
  setup() {
    router.push({
      path: "/",
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
  width: 100%;
  height: 100%;
}
</style>
