import { createRouter,createWebHashHistory } from "vue-router";

const router = createRouter({
    history: createWebHashHistory(),
    routes:[
        {
            path: '/',
            meta: {
                onlyOne: true,
            },
            component: () => import('@/components/MainPage.vue'),
            children: [
                {
                    path: 'JsonFormat',
                    component: () => import('@/components/JsonFormat.vue'),
                },
                {
                    path: 'JWTUtil',
                    component: () => import('@/components/JWTUtil.vue'),
                },
                {
                    path: 'Base64Util',
                    component: () => import('@/components/Base64Util.vue'),
                },
                {
                    path: 'URLTranscodeUtil',
                    component: () => import('@/components/URLTranscodeUtil.vue'),
                },
                {
                    path: 'MD5Util',
                    component: () => import('@/components/MD5Util.vue'),
                },
                {
                    path: 'QRCodeUtil',
                    component: () => import('@/components/QRCodeUtil.vue'),
                },
                {
                    path: 'CompareUtil',
                    component: () => import('@/components/CompareUtil.vue'),
                },
                {
                    path: 'PubDescription',
                    component: () => import('@/components/PubDescription.vue'),
                }
            ]
        }

    ]
})
export default router;